import { PremiumStatusEmitterService } from './../../../utils/sharePremiumStatus.service';
import { CustomErrorHandler } from './../../../utils/errorhandler.service';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { ChannelsDataService } from '../../../channels/channels-data.service';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})

export class SearchComponent implements OnInit, OnDestroy {
  private channelData: ChannelsDataService;
  public sessionStorage: WebStorageService;

  public HiddenValue: Boolean = true;
  private router: any;
  public searchValue = '';
  public allChannels: any[] = [];
  public isUser: boolean;
  public SearchResults: any[] = [];
  premiumUnlockStatus: boolean= false;
  private ngUnsubscribe: Subject<any> = new Subject();
  public loading: boolean = false;

  constructor(
    public channelsData: ChannelsDataService,
    @Inject(SESSION_STORAGE) public sessionStorages: WebStorageService,
    private routers: Router,
    private customErrorHandler: CustomErrorHandler,
    private premiumStatusEmitterService: PremiumStatusEmitterService
  ) {
    this.channelData = channelsData;
    this.router = routers;
    this.sessionStorage = sessionStorages;
  }

  ngOnInit() {
    this.isUser = JSON.parse(localStorage.getItem('userData')) ? true : false;
      this.premiumStatusEmitterService.premiumStatus$
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe(premiumStat =>{
        this.premiumUnlockStatus=premiumStat;
      });
  }

  SearchValue(event: any) {
    this.HiddenValue = false;
    this.allChannels = [];
    var channelData = this.channelData.datasharing();
    for (var i = 0; i < channelData.length; i++) {
      for (var j = 0; j < channelData[i].channelList.length; j++) {
          this.allChannels.push(channelData[i].channelList[j]);          
      }
    }

    this.searchValue = event.target.value;
    if (this.searchValue.length > 0) {
      var result = this.allChannels.filter(ChannelSearch => {
        return ChannelSearch.channelName
          .toLowerCase()
          .includes(this.searchValue.toString().toLowerCase());
      });
      this.SearchResults = [];
      this.SearchResults = result;
         let searchchannels = this.SearchResults.filter((ChannelDuplicate, index) => {
           if(!(this.allChannels.indexOf(ChannelDuplicate) === index)){
             return ChannelDuplicate;
           }
         } );
      this.SearchResults = [];
      this.SearchResults = searchchannels;
      result = [];
    } else {
      this.SearchResults = [];
    }
  }

  playSearchChannel(channel) {
    this.HiddenValue = true;
    if(this.isUser){
    	// this.channelData.shareMessage()
      this.channelData.currentplaying(channel);
      this.channelData.setSelectedChannel(channel);
      this.checkChannelPlayable(channel);
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    }
    else{
      this.router.navigate(['/authentication']);
    }
  }

  checkChannelPlayable(channel){
    this.channelsData.setSelectedChannel(channel);
    this.channelsData.getChannelPlayableUrl(channel, 'live', 'sd')
    .pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        data => this.channelUrlResponse(200, data, channel),
        err => this.channelUrlResponse(err.status, err, channel)
      );
  }

  channelUrlResponse(status, response, channel){
    console.log(status);
   if(response.status !== 200){
        if(response.status === 402){
          return this.channelsData.getChannelById(channel.id)
          .pipe(takeUntil(this.ngUnsubscribe)).subscribe(
            data => this.channelByIdResponse(data.status, data),
             err => this.channelByIdResponse(err.status, err )
            );
        }
      return;
    }

  return this.router.navigate(['/live-tv']);
  }

  channelByIdResponse(status, response){
    if(JSON.parse(response._body).isGold){
      this.routers.navigate(['/package']);
    }

    if(JSON.parse(response._body).isPremium){
      this.routers.navigate(['/purchase']);
    }
  }

  onClickedOutside(e: Event) {
    this.searchValue = "";
    this.HiddenValue = true;
  }
  searchMore(){
    if(this.isUser){
    this.loading = true;
  	this.channelData.searchMoreResults(this.searchValue)
    .pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        data => this.searchMoreResultsResponse(data.status, data),
        err => this.searchMoreResultsResponse(err.status, err)
      );
    }
  }
  searchMoreResultsResponse(response, data){
    if(response=== 403 || response === 0){      
      this.customErrorHandler.handle403Error(this.router.url);
      this.loading = false;
    }
    this.HiddenValue = false;    
  	this.SearchResults = [];
    this.SearchResults = JSON.parse(data._body);
    this.loading = false;
  }

  ngOnDestroy(){
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
