import { DataEmitterService } from './../utils/data-emitter.service';
import { PremiumStatusEmitterService } from './../utils/sharePremiumStatus.service';
import { Router } from '@angular/router';
import {Injectable, Inject} from '@angular/core';
import {environment} from '../../environments/environment';
import {Http, RequestOptions, Headers} from '@angular/http';
import { map } from 'rxjs/operators';
import {SESSION_STORAGE, WebStorageService} from 'angular-webstorage-service';
import { BehaviorSubject,Observable,Subject } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { EventEmitter } from 'protractor';

@Injectable({
  providedIn: 'root'
})

export class ChannelsDataService {
    public sessionStorage: WebStorageService;
    public user: any;
    public Channelsdata: any ;
    public RecentlyPlayedhistory: any [] = [];
    public channelLink: any = '';
    public selectedChannel: any;
    public NowPlaying:  any;
    shareChannel$: Observable<any>;
    private SharedChannel = new Subject<any>();
    baseUrl = environment.baseUrl;
    public PurchaseInfo;
    public premiumPurchaseStatus = new BehaviorSubject(false);
    public recentlyPlayedChannelsList: any[] = [];
    public channelPurchase ;

  constructor(@Inject(SESSION_STORAGE) public sessionStorages: WebStorageService,
    private http: Http, public router: Router,
    private premiumStatusEmitterService: PremiumStatusEmitterService) {
        this.sessionStorage = sessionStorages;
         this.shareChannel$ = this.SharedChannel.asObservable();
         this.user = JSON.parse(localStorage.getItem('userData'));
         if(localStorage.getItem('recentlyPlayedChannels') != null){
          this.recentlyPlayedChannelsList= JSON.parse(localStorage.getItem('recentlyPlayedChannels'));
        }
    }

    getChannelList(userStatus) {
        let finalUrl = this.baseUrl + 'app/channels?replete=true';
        let options : any = '';
        if (userStatus){

            let headers = new Headers({
                'Authorization': this.user.jwt,
                crossDomain: true,
                dataType: 'jsonp',
                AccessControlAllowOrigin: "https://www.worldtvgo.com/"
            });

            options = new RequestOptions({headers: headers});

            finalUrl = this.baseUrl + 'users/'+ this.user.userId +'/webchannels?appkey=' + this.user.appKey;
        }

        return this.http.get(finalUrl,
            options).pipe(map(
            res => {
                this.Channelsdata = res;
                return res;
            },
            err => {
                return err;
            }
        ));

    }

    setFavrouite(channelId){
       let headers = new Headers({
                'Authorization': this.user.jwt,
                crossDomain: true,
                dataType: 'jsonp'
            });

            let options = new RequestOptions({headers: headers});

            let finalUrl = this.baseUrl + 'users/'+ this.user.userId +'/channels/favourites?channel-ids=' + channelId + '&appkey=' + this.user.appKey ;
            return this.http.put(finalUrl,channelId, options
            ).pipe(map(
            res => {

                return res;
            },
            err => {
                return err;
            }
        ));
    }

    getGenreChannelListData(genereID){
    //   let headers = new Headers({
    //     'Authorization': this.user.jwt
    // });
    // let options = new RequestOptions({headers: headers});
    // let finalUrl = this.baseUrl + 'users/'+ this.user.userId +'/genres/' + genereID + '/channels?appkey=' + this.user.appKey;
       
    let finalUrl = this.baseUrl+'app/genres/' + genereID + '/channels';
        // let finalUrl = 'https://staging.worldtvgo.com/api/v1/app/genres/' + genereID + '/channels';
         return this.http.get(finalUrl).pipe(map(
        res => { return res; },
        err => { return err; }
    ));
    }

    getLangChannelListData(langID) {
      let headers = new Headers({
             'Authorization': this.user.jwt,
              crossDomain: true,
              dataType: 'jsonp'
         });
         let options = new RequestOptions({headers: headers});

         let finalUrl = this.baseUrl + 'users/' + this.user.userId + '/languages/' + langID + '/channels?appkey=' + this.user.appKey;

          return this.http.get(finalUrl,
            options).pipe(map(
         res => {

             return res;
         },
         err => {
             return err;
         }
     ));

     }

      getAllChannelList(){
            let headers = new Headers({
                'Authorization': this.user.jwt,
                crossDomain: true,
              dataType: 'jsonp'
            });

            let options = new RequestOptions({headers: headers});

            let finalUrl = this.baseUrl + 'users/'+ this.user.userId +'/webchannels?appkey=' + this.user.appKey + "&replete=true";
             return this.http.get(finalUrl,
            options).pipe(map(
            res => {
                this.Channelsdata = res;
                return res;
            },
            err => {
                return err;
            }
        ));
      }
    
  datasharing(){
      if(this.Channelsdata === undefined){
         return null;
      }
      return JSON.parse(this.Channelsdata._body);
  }

  currentplaying(NowPlaying){
    DataEmitterService.get('currentlyChannelToBePlayed').emit(NowPlaying);
      this.SharedChannel.next(NowPlaying);
      this.setSelectedChannel(NowPlaying);
      DataEmitterService.get('hasPlayedChannel').emit(true);
  }

  getCurrentPlaying(){
      return this.NowPlaying;
  }
  getChannelPurchase(){
    return this.channelPurchase;
  }
  setChannelPurchase(channel){
    this.channelPurchase = channel;
  }

  searchMoreResults(inputValue){
    let finalUrl = this.baseUrl;

    const httpOptions = {
      headers: new Headers({
        'Authorization': this.user.jwt,
                      crossDomain: true,
              dataType: 'jsonp'
      })
    };

    finalUrl = this.baseUrl + 'channels/search/findByName?name=' + inputValue + '&user-id' + this.user.userId;

    return this.http.get(finalUrl,
      httpOptions).pipe(map(
        res => {
            return res;
        },
        err => {
            return err;
        }
    ));
  }

  getChannelPlayableUrl(channel, CheckValue, CheckHdSd){
    if(!JSON.parse(localStorage.getItem('userData'))){
        this.router.navigate(['/authentication']);
    }
    this.RecentlyPlayedhistory.push(channel);

    let headers = new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': this.user.jwt,
                      crossDomain: true,
              dataType: 'jsonp'
    });

    let options = new RequestOptions({headers: headers});
    if(CheckValue == "live"){
      // var url= channel.linksHATEOAS[0].href;
      var url = this.baseUrl + "channels/" + channel.id + "/channel-link"
    }
    else if(CheckValue == "DVR"){
      let url= channel.linksHATEOAS[1].href;
    }

    let FinalUrl = url +'?appkey=' + this.user.appKey + '&type =' + CheckHdSd ;
    return this.http.get( FinalUrl ,
      options).pipe(map(
      (res: any) => {
        if (res.status == 200){
           this.NowPlaying = channel;
           // begin of code for recently played channel
           if(channel != null){
            if(this.recentlyPlayedChannelsList != null){
              var checkExistanceInRecentlyPlayedList=this.recentlyPlayedChannelsList.some(obj=>obj['id']===channel.id);
              if(checkExistanceInRecentlyPlayedList){
                let indexOfRecentlyPlayedChannel=this.recentlyPlayedChannelsList.findIndex(obj=>obj['id']===channel.id);
                this.recentlyPlayedChannelsList.splice(indexOfRecentlyPlayedChannel,1);
                this.recentlyPlayedChannelsList.splice(0,0,channel);
              }else{
                this.recentlyPlayedChannelsList.unshift(channel);
              }
            }
              DataEmitterService.get('recentlyPlayedChannels').emit([JSON.stringify(this.recentlyPlayedChannelsList)]);
              localStorage.setItem('recentlyPlayedChannels',JSON.stringify(this.recentlyPlayedChannelsList));
          }
           // end of code for recently played channel
          this.sessionStorage.remove('currentPlaying');
          this.sessionStorage.set('currentPlaying', channel);
        }else if(res.status == 401 || res.status == 402){
          this.channelPurchase = channel;
        }
          this.channelLink = JSON.parse(res._body).href;
          return res;
      },
      err => {
          return err;

      }
    ));
  }

  getChannelById(id){
    let headers = new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': this.user.jwt,
                      crossDomain: true,
              dataType: 'jsonp'
    });

    let options = new RequestOptions({headers: headers});

    let url = this.baseUrl + 'users/' + this.user.userId + '/channels/' + id;
    let query =  '?appkey='+ this.user.appKey;

    return this.http.get( url + query,  options ).pipe(map(
      (res: any) => {
        this.channelPurchase = JSON.parse(res._body);
          return res;
      },
      err => {
          return err;
      }
    ));
  }

  setChannelLink(){
      this.channelLink = '';
  }

  getChannelLink(){
    return this.channelLink;
  }

  setSelectedChannel(channel){
      this.selectedChannel = channel;
  }

  getSelectedChannel(){
      return this.selectedChannel;
  }

  // begin of method for checking if the user has bought premium packages or not
  getPremiumUnlockStatus(){
    if(this.user){    
    var premiumUnlockStatusURL = this.baseUrl+'users/'+this.user['userId']+'/premium-unlock?appkey='+this.user.appKey;
    let headers = new Headers({
      'Authorization': this.user.jwt,
                    crossDomain: true,
              dataType: 'jsonp'
    });

    let options = new RequestOptions({headers: headers});
    this.http.get(premiumUnlockStatusURL,options).subscribe((res: any) =>{
      this.PurchaseInfo=JSON.parse(res._body).purchased;
      this.premiumStatusEmitterService.premiumStatusEmissionMethod(JSON.parse(res._body).purchased);
      });
    }else{
      this.PurchaseInfo= false;
    }
  }

  sharePremiumStatus(){
    return this.PurchaseInfo;
  }

  getFmList(){
     return this.http.get( "https://mnm.nettvnepal.com.np/api/v2/fm/list").pipe(map(
      (res: any) => {
          return res;
      },
      err => {
          return err;
      }
    ));
  }

  getFavrouiteChannel(){
    if(this.user){    
      var getFavrouiteChannelList = this.baseUrl+'users/'+this.user['userId']+'/channels/favourites?appkey='+this.user.appKey;
      let headers = new Headers({
        'Authorization': this.user.jwt,
                      crossDomain: true,
              dataType: 'jsonp'
      });

      let options = new RequestOptions({headers: headers});
      return this.http.get(getFavrouiteChannelList,  options ).pipe(map(
      (res: any) => {
          return res;
      },
      err => {
          return err;
      }
    ));
    }
  }
  
  getFeatureChannel(){
    if(this.user){    
      var getFavrouiteChannelList = this.baseUrl+'users/'+this.user['userId']+'/channels/featured?appkey='+this.user.appKey;
      let headers = new Headers({
        'Authorization': this.user.jwt,
                      crossDomain: true,
              dataType: 'jsonp'
      });

      let options = new RequestOptions({headers: headers});
      return this.http.get(getFavrouiteChannelList,  options ).pipe(map(
      (res: any) => {
          return res;
      },
      err => {
          return err;
      }
    ));
    }
  }

  getNewsData() {
    return this.http.get('https://mnm.nettvnepal.com.np/api/v2/wtg-news?page=1'
      ).pipe(map(
        res => {
          return res;
        },
        err => {
          return err;
        }
      ));
  }
}
