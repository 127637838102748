import {Component, Inject, OnInit, OnDestroy} from '@angular/core';
import {SESSION_STORAGE, WebStorageService} from 'angular-webstorage-service';
import {environment} from '../../environments/environment';
import {Event, ActivatedRoute, Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError} from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { DataEmitterService } from './../utils/data-emitter.service';
import { Subject } from 'rxjs';


@Component({
    selector: 'app-master',
    templateUrl: './master.component.html',
    styleUrls: ['./master.component.scss']
})
export class MasterComponent implements OnInit, OnDestroy {

    public sessionStorage: WebStorageService;
    private router: any;
    showLoadingIndicator = true;
      public status: Boolean = true;
  public dropdownshow: Boolean = true;
  public notSmall: Boolean = false;
  public userData: any;
  public dropdownValue = false;
  public ChangeActiveColor: boolean= false;
  public clickedRoute;
  public hasRecentlyPlayedChannel: boolean = false;
  public currentPlaying;
  private ngUnsubscribe: Subject<any> = new Subject();

    constructor(@Inject(SESSION_STORAGE) public sessionStorages: WebStorageService, private route: Router) {
        this.sessionStorage = sessionStorages;
        this.router = route;
        this.route.events
        .pipe(takeUntil(this.ngUnsubscribe)).subscribe((routerEvent: Event) => {
            if (routerEvent instanceof NavigationStart) {
              this.showLoadingIndicator = true;
            }
            if (routerEvent instanceof NavigationEnd ||
              routerEvent instanceof NavigationCancel ||
              routerEvent instanceof NavigationError) {
              this.showLoadingIndicator = false;
            }
          });
    }

    ngOnInit() {
      window.scrollTo(0, 0);

    DataEmitterService.get('hasPlayedChannel')
    .pipe(takeUntil(this.ngUnsubscribe)).subscribe(res =>{
      if(res=== true ){
      this.currentPlaying =JSON.parse(sessionStorage.getItem('currentPlaying'));
      console.log("checking data",this.currentPlaying);
     this.userData = JSON.parse(localStorage.getItem('userData'));
        this.router.events
        .pipe(takeUntil(this.ngUnsubscribe)).subscribe((event)=>{
          if(event instanceof NavigationEnd){
            if(event.url === '/live-tv'){
              this.hasRecentlyPlayedChannel= false;
            }else{
              this.hasRecentlyPlayedChannel= true;
            }
          }
        });
      }
    });

    }
    hideContinue() {
      this.hasRecentlyPlayedChannel= false;
    }
    ngOnDestroy(){
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    }
}
