
import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import {LOCAL_STORAGE, WebStorageService} from 'angular-webstorage-service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor( public router: Router, 
     @Inject(LOCAL_STORAGE) public localStorage: WebStorageService) {}

  canActivate(): boolean {
    if (this.localStorage.get('userData')) {
      return true;
    }
    this.router.navigate(['/authentication']);
    return false;
  }
}