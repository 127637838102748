import { DataEmitterService } from './../../../utils/data-emitter.service';
import { Component, OnInit, OnChanges, SimpleChanges, Inject, OnDestroy } from '@angular/core';
import { WebStorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare let $: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})


export class MenuComponent implements OnInit, OnDestroy {

  public status: Boolean = true;
  public dropdownshow: Boolean = true;
  public notSmall: Boolean = false;
  public sessionStorage: WebStorageService;
  public userData: any;
  public router: any;
  public dropdownValue = false;
  public ChangeActiveColor: boolean= false;
  public clickedRoute;
  public hasRecentlyPlayedChannel: boolean = false;
  private ngUnsubscribe: Subject<any> = new Subject();
  // public screenWidth: Number = 768;

  constructor(@Inject(SESSION_STORAGE) public sessionStorages: WebStorageService,private route: Router, private routes:ActivatedRoute ) {
    this.sessionStorage = sessionStorages;
    this.router = route;
  }
  toggleDropdown() {
    this.dropdownshow = !this.dropdownshow;
  }
  toggleMenu() {
    this.status = !this.status;
  }
  menuHide(e: Event) {
    this.dropdownshow=true;   
  }
  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    DataEmitterService.get('hasPlayedChannel')
    .pipe(takeUntil(this.ngUnsubscribe)).subscribe(res =>{
      if(res=== true ){
        this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event)=>{
          if(event instanceof NavigationEnd){
            if(event.url === '/live-tv'){
              this.hasRecentlyPlayedChannel= false;
            }else{
              this.hasRecentlyPlayedChannel= true;
            }
          }
        });
      }
    });
    // if ( window.innerWidth > 1023) {
    //   this.notSmall = !this.notSmall;
    // }
    // window.addEventListener('resize', function() {
    //   if ( window.matchMedia('(min-width: 768px)').matches) {

    //     //  this.notSmall = true;

    //   } else {
    //   }
    // // return(this.notSmall);
    // });
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   if ( 1 > 0) {
  //     this.notSmall = !this.notSmall;
  //   }

  // }

  ngOnDestroy(){
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  
}
