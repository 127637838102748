import { Injectable } from '@angular/core';
import { Request, XHRBackend, RequestOptions, Response, Http, RequestOptionsArgs, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { catchError  } from 'rxjs/operators';
import { CustomErrorHandler } from './errorhandler.service';
import { Router } from '@angular/router';


@Injectable()
export class AuthenticatedHttpService extends Http {

  constructor(backend: XHRBackend, defaultOptions: RequestOptions, 
              private customErrorHandler: CustomErrorHandler, private router: Router) {
    super(backend, defaultOptions);
  }

  request(url: string | Request, options?: RequestOptionsArgs): Observable<Response> {
    return super.request(url, options).pipe(catchError((error: Response) => {
            if (error.status === 403) {
                // console.log('I am 403 url url => '+JSON.stringify(url));
                console.log('I am 403 error details => '+JSON.stringify(error));
                // console.log('I am 403 current url from angular router => '+JSON.stringify(this.router.url));
                this.customErrorHandler.handle403Error(this.router.url);
            }else{
              return super.request(url, options);
            }
            // return Observable.throw(error);
        }));
  }
}