import { Observable, Subject } from 'rxjs';
import { Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PremiumStatusEmitterService {
  premiumStatus$: Observable<any>;
  private premiumStatusSubject = new Subject<any>();

  constructor() {
      this.premiumStatus$ = this.premiumStatusSubject.asObservable();
  }

  premiumStatusEmissionMethod(status) {
      this.premiumStatusSubject.next(status);
  }
  }