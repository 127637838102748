// export const environment = {
//   production: true,
//   LogoURL: "assets/logo.png",
//     baseUrl: 'https://app.worldtvgo.com/api/v1/',
//     firebase: {
//       apiKey: 'AIzaSyAVzfkLP_ORy1KefOKtpnxBAeaA_1BHfKQ',
//       authDomain: 'wtv-go.firebaseapp.com',
//       databaseURL: 'https://wtv-go.firebaseio.com',
//       projectId: 'wtv-go',
//       storageBucket: 'wtv-go.appspot.com',
//       messagingSenderId: '444343096891'
//     }
// };

// WorldTVGO India


export const environment = {
  production: true,
  LogoURL: "assets/logoIndia.png",
    baseUrl: 'https://worldtvindia.com/api/v1/',
    firebase: {
      apiKey: 'AIzaSyAVzfkLP_ORy1KefOKtpnxBAeaA_1BHfKQ',
      authDomain: 'wtv-go.firebaseapp.com',
      databaseURL: 'https://wtv-go.firebaseio.com',
      projectId: 'wtv-go',
      storageBucket: 'wtv-go.appspot.com',
      messagingSenderId: '444343096891'
    }
};
