import {Component, Inject, OnInit, OnDestroy} from '@angular/core';
import {SESSION_STORAGE, WebStorageService} from 'angular-webstorage-service';
import { RouterModule } from '@angular/router';
import {Event, Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PushNotificationService } from './utils/pushnotification.service';
import { PushNotificationEmitterService } from './utils/pushnotificationemitter.service';
import { Observable, fromEvent, Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
  title = 'worldtvgo-web-app';
    private sessionStorage: any;
    private router: any;
    showLoadingIndicator = true;
    private ngUnsubscribe: Subject<any> = new Subject();

  onlineEvent: any;
  offlineEvent: any;

  constructor(@Inject(SESSION_STORAGE) public sessionStorages: WebStorageService, 
              private route: Router,
              private toastrService: ToastrService,
              private pushNotificationService: PushNotificationService,
              private pushNotificationEmitterService: PushNotificationEmitterService){
      this.sessionStorage = sessionStorages;
      this.router = route;
      this.route.events
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe((routerEvent: Event) => {
        if (routerEvent instanceof NavigationStart) {
          this.showLoadingIndicator = true;
        }
        if (routerEvent instanceof NavigationEnd ||
          routerEvent instanceof NavigationCancel ||
          routerEvent instanceof NavigationError) {
          this.showLoadingIndicator = false;
        }
      });
  }

    ngOnInit() {
    this.pushNotificationService.requestPermission();
    this.pushNotificationService.receiveMessage();
    this.pushNotificationEmitterService.pushedNotification$
    .pipe(takeUntil(this.ngUnsubscribe)).subscribe(notification =>{
        this.toastrService.info('Test',JSON.stringify(notification.notification['body']));
    });
      window.scrollTo(0, 0);
      this.checkNetworkstatus();
  }

  checkNetworkstatus(){
    /**
    * Get the online/offline status from browser window
    */
   this.onlineEvent = fromEvent(window, 'online');
   this.offlineEvent = fromEvent(window, 'offline');

   this.onlineEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe(e => {
     this.toastrService.success('Back to online', 'online');
   });

   this.offlineEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe(e => {
     this.toastrService.error('Connection lost! You are not connected to internet', 'offline');
   });
  }

  ngOnDestroy(){
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
