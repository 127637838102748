import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService as AuthGuard } from './authentication/auth-guard.service';

const routes: Routes = [
    {
        path: '',
        loadChildren: './home/home.module#HomeModule',
    },
    {
        path: 'regionchange/:regionchangestatus',
        loadChildren: './home/home.module#HomeModule'
    },
    {
        path: 'verify/reset-password',
        loadChildren: './authentication/authentication.module#AuthenticationModule'
    },
        {
        path: 'home',
        loadChildren: './home/home.module#HomeModule',
    },
    {
        path: 'authentication',
        loadChildren: './authentication/authentication.module#AuthenticationModule'
    },
    {
        path: 'navbar',
        loadChildren: './master/navbar/navbar.module#NavbarModule'
    },
    {
         path: 'user',
         loadChildren: './master/user/user.module#UserModule',
         canActivate: [AuthGuard]
    },
    {
        path: 'live-tv',
        loadChildren: './live-tv/live-tv.module#LiveTvModule',
        canActivate:  [AuthGuard]
    },
    {
        path: 'purchase',
        loadChildren: './purchase/purchase.module#PurchaseModule',
        canActivate:  [AuthGuard]
    },
    {
        path: 'package',
        loadChildren: './packages/packages.module#PackagesModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'payment',
        loadChildren: './payment/payment.module#PaymentModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'roku',
        loadChildren: './roku/roku.module#RokuModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'news',
        loadChildren: './news/news.module#NewsModule',
    },
    {
        path: 'fm',
        loadChildren: './fm/fm.module#FmModule',
    },
    {
        path: 'miscellaneous',
        loadChildren: './miscellaneous/miscellaneous.module#MiscellaneousModule'
    },
    {
        path: 'notifications',
        loadChildren: './notification/notification.module#NotificationModule',
        canActivate: [AuthGuard]
    },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
