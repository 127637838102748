import {Component, Inject, OnInit} from '@angular/core';
import {SESSION_STORAGE, WebStorageService} from 'angular-webstorage-service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    public sessionStorage: WebStorageService;
    public router: any;
    public userData: any;
    public LogoURl = environment.LogoURL;

  constructor(@Inject(SESSION_STORAGE) public sessionStorages: WebStorageService, private route: Router) {
      this.sessionStorage = sessionStorages;
      this.router = route;

  }

  ngOnInit() {
      this.userData = JSON.parse(localStorage.getItem('userData'));
  }

  // for development
  // logout() {
  //   this.sessionStorage.remove('userData');
  //     location.reload();
  //     this.router.navigate(['/']);
  // }

}
