import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu/menu.component';
import { SearchComponent } from './search/search.component';
import { NavbarComponent} from './navbar.component';
import { RouterModule} from '@angular/router';
import { NavbarRoutingModule } from './navbar-routing.module';
import { ClickOutsideModule } from 'ng-click-outside';
// import {MiscellaneousModule} from '../../miscellaneous/miscellaneous.module';


@NgModule({
  declarations: [ MenuComponent, SearchComponent, NavbarComponent ],
  imports: [
        CommonModule,
        RouterModule,
        NavbarRoutingModule,
        ClickOutsideModule,
        // MiscellaneousModule
      ],
  exports: [
        NavbarComponent
    ]
})
export class NavbarModule { }
