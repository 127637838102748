import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { AngularFireFunctions  } from "@angular/fire/functions";
import { BehaviorSubject } from "rxjs";
import { take, map } from "rxjs/operators";
import { environment } from "src/environments/environment.prod";
import { PushNotificationEmitterService } from "./pushnotificationemitter.service";
import { checkAndUpdateElementInline } from '@angular/core/src/view/element';
import { tokenKey } from '@angular/core/src/view';

@Injectable()
export class PushNotificationService {
  //usr: any= JSON.parse(localStorage.getItem('userData'));
  private baseURL = environment.baseUrl;
  tokenRegisterStatusNotLoggedIn: boolean = false;
  tokenRegisterStatusLoggedIn: boolean = false;

  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireFunctions: AngularFireFunctions ,
    private angularFireMessaging: AngularFireMessaging,
    private http: HttpClient,
    private pushnotificationEmitterService: PushNotificationEmitterService
  ) {
    this.angularFireMessaging.messaging.subscribe(_messaging => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      token => {
        this.updateToken(1, token);
      },
      err => {
      }
    );
  }

  updateToken(userId, token) {
    // this.angularFireFunctions.httpsCallable('subscribeToTopic')({topic:'wtvgo' , token: reqToken})
    // end of code for topic subscription
    if (localStorage.getItem("userData") != null) {
      var usr = JSON.parse(localStorage.getItem("userData"));
      var reqHeader = new HttpHeaders({
        Authorization: usr.jwt
      });
      if (this.tokenRegisterStatusLoggedIn === false) {
        let firebaseTokenRegistrationURL =
          this.baseURL +
          "users/" +
          usr.userId +
          "/firebase-web?appkey=" +
          usr.appKey +
          "&token=" +
          token;
        this.http
          .put(firebaseTokenRegistrationURL, null, { headers: reqHeader })
          .subscribe(
            res => {
              this.tokenRegisterStatusLoggedIn = true;
            },
            (err: any) => {
            }
          );
      }
    } else {
      // var header=new HttpHeaders({
      //   'No-Auth':'True'
      // })
      if (this.tokenRegisterStatusNotLoggedIn === false) {
        let firebaseTokenRegistrationURL2 =
          this.baseURL + "app/firebase-web?token=" + token;
        this.http.put(firebaseTokenRegistrationURL2, null).subscribe(
          res => {
            this.tokenRegisterStatusNotLoggedIn = true;
          },
          (err: any) => {
          }
        );
      }
    }
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        this.pushnotificationEmitterService.notificationEmissionMethod(payload);
      },
      err => {
      }
    );
  }
}