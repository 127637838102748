import { AutoLoginCredentials } from './autologin-credentials';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class CustomErrorHandler{
    private returningUrl= null;
    constructor(private autoLoginCredentials: AutoLoginCredentials,
                private router: Router){}

    handle403Error(returningURL): any{
            console.log("403 error invoked");
        if(localStorage.getItem('userData') != null){
            const userCredentials : any= JSON.parse(localStorage.getItem('userData'));
            this.autoLoginCredentials.autologinToken=userCredentials['autoLoginToken'];
            this.autoLoginCredentials.appKey=userCredentials['appKey'];
            this.autoLoginCredentials.userID=userCredentials['userId'];
            localStorage.removeItem('userData');
            localStorage.removeItem('recentlyPlayedChannels');
            localStorage.clear();
            if(this.returningUrl === null){
                this.returningUrl= returningURL;
            }
            this.router.navigate(['/authentication'], { queryParams: { returnUrl: returningURL }});
        }else{
            this.router.navigate(['/authentication']);
        }
    }
}