import { Observable, Subject } from 'rxjs';
import { Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class PushNotificationEmitterService {
  pushedNotification$: Observable<any>;
  private pushedNotificationSubject = new Subject<any>();

  constructor() {
      this.pushedNotification$ = this.pushedNotificationSubject.asObservable();
  }

  notificationEmissionMethod(notification) {
      // we can do stuff with data if we want
      this.pushedNotificationSubject.next(notification);
  }
  }