// Pre Defined Modules

import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { BrowserModule} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule} from '@angular/core';
import { HttpModule, Http} from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule} from '@angular/router';
import { HashLocationStrategy, LocationStrategy} from '@angular/common';
import { ServiceWorkerModule} from '@angular/service-worker';
import { ToastrModule } from 'ngx-toastr';
import { StorageServiceModule} from 'angular-webstorage-service';
import { AngularFontAwesomeModule} from 'angular-font-awesome';
import { environment} from '../environments/environment';
import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider} from 'angular-6-social-login-v2';
import { ImagePreloadDirective } from './image-preload.directive';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';

//User Defined Module

import { AppRoutingModule} from './app-routing.module';
import { AppComponent} from './app.component';
import { MasterComponent} from './master/master.component';
import { NavbarModule} from './master/navbar/navbar.module';
import { FooterModule} from './master/footer/footer.module';
// import { ChannelsModule} from './channels/channels.module';
// import {MiscellaneousModule} from './miscellaneous/miscellaneous.module';

import { ChannelsDataService} from './channels/channels-data.service';
import { AutoLoginCredentials } from './utils/autologin-credentials';
import { PremiumStatusEmitterService } from './utils/sharePremiumStatus.service';
import { AuthGuardService as AuthGuard } from './authentication/auth-guard.service';
import { AuthenticatedHttpService } from './utils/authenticatedHttpService .service';
import { DataEmitterService } from './utils/data-emitter.service';
import { CustomErrorHandler } from './utils/errorhandler.service';
import { PushNotificationEmitterService } from './utils/pushnotificationemitter.service';
import { PushNotificationService } from './utils/pushnotification.service';

export function getAuthServiceConfigs() {
    let config = new AuthServiceConfig(
        [
            {
                id: FacebookLoginProvider.PROVIDER_ID,
                provider: new FacebookLoginProvider('2601162089900997')
            },
            {
                id: GoogleLoginProvider.PROVIDER_ID,
                provider: new GoogleLoginProvider('444343096891-plea6mnaf0nsv391sqtan1svo42mk35d.apps.googleusercontent.com')
            }
        ]);

    return config;
}

@NgModule({
    declarations: [
        AppComponent,
        MasterComponent,
        ImagePreloadDirective
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NavbarModule,
        HttpModule,
        HttpClientModule,
        StorageServiceModule,
        AngularFontAwesomeModule,
        // ChannelsModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        RouterModule,
        SocialLoginModule,
        HttpClientModule,
        ToastrModule.forRoot({timeOut: 4000, preventDuplicates: true}),
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireFunctionsModule,
        AngularFireModule.initializeApp(environment.firebase),
        // MiscellaneousModule,
        FooterModule
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: AuthServiceConfig,
            useFactory: getAuthServiceConfigs
        },
        { provide: Http, useClass: AuthenticatedHttpService },
        ChannelsDataService,
        PushNotificationService,
        PushNotificationEmitterService,
        AutoLoginCredentials,
        CustomErrorHandler,
        PremiumStatusEmitterService,
        AuthGuard,
        DataEmitterService
    ],
    exports: [],
    bootstrap: [AppComponent]
})

export class AppModule {
}
