
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   LogoURL: "assets/logo.png",
//   India: false,
//   baseUrl: 'https://app.worldtvgo.com/api/v1/',
//   // baseUrl: 'http://localhost:8084/wtvgo/api/v1/',
//     url: '/',
//     firebase: {
//       apiKey: 'AIzaSyAVzfkLP_ORy1KefOKtpnxBAeaA_1BHfKQ',
//       authDomain: 'wtv-go.firebaseapp.com',
//       databaseURL: 'https://wtv-go.firebaseio.com',
//       projectId: 'wtv-go',
//       storageBucket: 'wtv-go.appspot.com',
//       messagingSenderId: '444343096891'
//     }
// };

// WorldTVGO India

export const environment = {
  production: false,
  LogoURL: "assets/logoIndia.png",
  baseUrl: 'https://worldtvindia.com/api/v1/',
    url: '/',
    firebase: {
      apiKey: 'AIzaSyAVzfkLP_ORy1KefOKtpnxBAeaA_1BHfKQ',
      authDomain: 'wtv-go.firebaseapp.com',
      databaseURL: 'https://wtv-go.firebaseio.com',
      projectId: 'wtv-go',
      storageBucket: 'wtv-go.appspot.com',
      messagingSenderId: '444343096891'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
